import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@hydropop-io/calciupop";
import type { MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Hydropop Account",
  viewport: "width=device-width,initial-scale=1",
});
export async function loader() {
  return json({ ENV: { HYDROPOP_ROOT: process.env.HYDROPOP_ROOT } });
}
export default function App() {
  const data = useLoaderData();
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <ChakraProvider theme={theme} resetCSS>
          <Outlet context={data} />
        </ChakraProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}
